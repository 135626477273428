import { FunctionComponent, useEffect, useState } from 'react';
import { ConfigContentPropsInterface } from '../ConfigContentPropsInterface';
import styles from '../IntercomConfigMenuContent.module.scss';
import { ExpansionPanel, ExpansionPanelActionEvent, ExpansionPanelContent } from '@progress/kendo-react-layout';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import DefaultViewControl from '../../../../../components/common/defaultViewControl/DefaultViewControl';

const DoorTypes = [
  { text: 'Apply power to open lock (fail-secure / fail-locked)', value: 'power_to_unlock' },
  { text: 'Apply power to close lock (fail-safe / fail-open)', value: 'power_to_lock' },
];

const SensorType = [
  { text: 'Circuit closes when door is closed (normally-open)', value: 'normally_open' },
  { text: 'Circuit opens when door is closed (normally-closed)', value: 'normally_closed' },
];

const UnlockDuration = [
  { text: '1 second', value: 1 },
  { text: '2 second', value: 2 },
  { text: '3 second', value: 3 },
  { text: '4 second', value: 4 },
  { text: '5 second', value: 5 },
  { text: '6 second', value: 6 },
  { text: '7 second', value: 7 },
  { text: '8 second', value: 8 },
  { text: '9 second', value: 9 },
  { text: '10 second', value: 10 },
];

const AlarmPeriod = [
  { text: '1 minute', value: 60 },
  { text: '2 minute', value: 120 },
  { text: '3 minute', value: 180 },
  { text: '4 minute', value: 240 },
  { text: '5 minute', value: 300 },
  { text: '10 minute', value: 600 },
  { text: '15 minute', value: 900 },
  { text: '20 minute', value: 1200 },
  { text: '30 minute', value: 1800 },
  { text: '45 minute', value: 2400 },
  { text: '1 hour', value: 3600 },
];

const IntercomConfigDoor: FunctionComponent<ConfigContentPropsInterface> = (props: ConfigContentPropsInterface) => {
  const [expanded, setExpanded] = useState({
    firstPanel: true,
    secondPanel: true,
    thirdPanel: true,
    fourthPanel: true,
  });
  const [doorEnable, setDoorEnable] = useState<boolean>();
  const [doorType, setDoorType] = useState<any>();
  const [doorDTMFMomentaryUnlock, setDoorDTMFMomentaryUnlock] = useState<string>();
  const [doorDTMFMomentaryUnlockDuration, setDoorDTMFMomentaryUnlockDuration] = useState<any>();
  const [doorDTMFPlayTone, setDoorDTMFPlayTone] = useState<boolean>();
  const [doorDTMFHangupAfterMomentaryCode, setDoorHangupAfterMomentaryCode] = useState<boolean>();
  const [doorDTMFPermanentUnlockEnable, setDoorPermanentUnlockEnable] = useState<boolean>();
  const [doorDTMFPermanentUnlockCode, setDoorPermanentUnlockCode] = useState<string>();
  const [doorDTMFPermanentLockCode, setDoorPermanentLockCode] = useState<string>();
  const [doorDTMFPlayToneAfterPermanentCode, setPlayToneAfterPermanentCode] = useState<boolean>();
  const [doorDTMFHangupAfterPermanentCode, setPlayHangupAfterPermanentCode] = useState<boolean>();
  const [doorSensorEnable, setSensorEnable] = useState<boolean>();
  const [doorSensorType, setSensorType] = useState<any>();
  const [doorSensorRelockWhenOpened, setDoorSensorRelockWhenOpened] = useState<boolean>();
  const [doorSensorAlarmEnable, setSensorAlarmEnable] = useState<boolean>();
  const [doorSensorAlarmTimeout, setSensorAlarmTimeout] = useState<any>();
  const [doorSensorAlarmDialNumber, setSensorAlarmDialNumber] = useState<string>();
  const [doorSensorAlarmMessageTone, setSensorAlarmMessageTone] = useState<any>();
  const [doorSensorAlarmMessageToneFilename, setSensorAlarmMessageToneFilename] = useState<string>();
  const [doorSensorAlarmDialRetry, setSensorAlarmDialRetry] = useState<any>();


  useEffect(() => {
    setValues(props.model);
  }, []);

  if (!props.model) {
    return (
      <></>
    );
  }

  const setValues = (model: any) => {
    if (model && model.dtmf && model.sensor) {
      const dtmf = model.dtmf;
      const sensor = model.sensor;
      setDoorEnable(model.enable);
      setDoorType({ text: DoorTypes.find((i) => i.value === model.type)?.text, value: model.type });
      setDoorDTMFMomentaryUnlock(dtmf.momentary_unlock);
      setDoorDTMFMomentaryUnlockDuration({
        text: UnlockDuration.find((i) => i.value === dtmf.momentary_unlock_duration)?.text,
        value: dtmf.momentary_unlock_duration,
      });
      setDoorDTMFPlayTone(dtmf.play_tone);
      setDoorHangupAfterMomentaryCode(dtmf.hangup_after_momentary_code);
      setDoorPermanentUnlockEnable(dtmf.permanent_unlock_enable);
      setDoorPermanentUnlockCode(dtmf.permanent_unlock);
      setDoorPermanentLockCode(dtmf.permanent_lock);
      setPlayToneAfterPermanentCode(dtmf.play_tone_after_permanent_code);
      setPlayHangupAfterPermanentCode(dtmf.hangup_after_permanent_code);
      setSensorEnable(sensor.enable);
      setSensorType({ text: SensorType.find((i) => i.value === sensor.type)?.text, value: sensor.type });
      setDoorSensorRelockWhenOpened(sensor.relock_when_opened);
      setSensorAlarmTimeout({ text: AlarmPeriod.find((i) => i.value === sensor.alarm.timeout)?.text, value: sensor.alarm.timeout });
      setSensorAlarmDialNumber(sensor.alarm.diak_number);
      setSensorAlarmMessageTone(sensor.alarm.message_tone);
      setSensorAlarmMessageToneFilename(sensor.alarm.message_filename);
      setSensorAlarmDialRetry({ text: AlarmPeriod.find((i) => i.value === sensor.alarm.dial_retry)?.text, value: sensor.alarm.dial_retry });
    }
  }
  const save = () => {
    let updateModel = {
      intercom:
        {
          door: {
            enable: doorEnable,
            type: doorType?.value,
            dtmf:
              {
                momentary_unlock: doorDTMFMomentaryUnlock,
                momentary_unlock_duration: doorDTMFMomentaryUnlockDuration?.value,
                play_tone: doorDTMFPlayTone,
                hangup_after_momentary_code: doorDTMFHangupAfterMomentaryCode,
                permanent_unlock_enable: doorDTMFPermanentUnlockEnable,
                permanent_unlock: doorDTMFPermanentUnlockCode,
                permanent_lock: doorDTMFPermanentLockCode,
                play_tone_after_permanent_code: doorDTMFPlayToneAfterPermanentCode,
                hangup_after_permanent_code: doorDTMFHangupAfterPermanentCode,
              },
            sensor: {
              enable: doorSensorEnable,
              type: doorSensorType?.value,
              relock_when_opened: doorSensorRelockWhenOpened,
              alarm: {
                enable: doorSensorAlarmEnable,
                timeout: doorSensorAlarmTimeout?.value,
                dial_number: doorSensorAlarmDialNumber,
                message_tone: doorSensorAlarmMessageTone,
                message_filename: doorSensorAlarmMessageToneFilename,
                dial_retry: doorSensorAlarmDialRetry?.value,
              },
            },
          },
        },
    };
    let updateConfigModel = {Config: updateModel, IsPartial: true}
    props.saveEvent(updateConfigModel);
  };

  const cancel = () => {
    setValues(props.model);
    props.cancelEvent();
  };
  return (
    <div className={styles.pageWrapperIntercomConfigContent} id={'IntercomConfigContentExpander'}>
      <div className={styles.panelRowSpaceBetween} style={{ margin: 10 }}>
        <div className={styles.pageTitle}>Door</div>
      </div>
      <ExpansionPanel
        title={'Door Lock Settings'}
        expanded={expanded.firstPanel}
        tabIndex={0}
        onAction={(event: ExpansionPanelActionEvent) => {
          setExpanded({...expanded, firstPanel: !expanded.firstPanel});
        }}
      >
        <div>
          {expanded.firstPanel}
          {expanded.firstPanel && (
            <ExpansionPanelContent>
              {props.editMode ?
                <div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Door Lock</div>
                      <Checkbox label={'Installed'} value={doorEnable} onChange={(ev) => setDoorEnable(ev.value)}/>
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Lock</div>
                      <DropDownList
                        data={DoorTypes}
                        textField="text"
                        dataItemKey="value"
                        value={doorType}
                        onChange={(ev) => setDoorType(ev.value)}
                      />
                    </div>
                  </div>
                </div>
                :
                <div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl checkboxView={true} checkboxLabel={'Installed'} label={'Door Lock'} value={doorEnable}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Lock'} value={doorType?.text}></DefaultViewControl>
                    </div>
                  </div>
                </div>
              }
            </ExpansionPanelContent>
          )}
        </div>
      </ExpansionPanel>
      <ExpansionPanel
        title={'Door Controls'}
        expanded={expanded.secondPanel}
        tabIndex={1}
        onAction={(event: ExpansionPanelActionEvent) => {
          setExpanded({...expanded, secondPanel: !expanded.secondPanel});
        }}
      >
        <div>
          {expanded.secondPanel}
          {expanded.secondPanel && (
            <ExpansionPanelContent>
              {props.editMode ?
                <div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Momentary Unlock Code</div>
                      <div className='k-form-field-wrap'>
                        <Input type='text' value={doorDTMFMomentaryUnlock}
                               onChange={(ev) => {
                                 setDoorDTMFMomentaryUnlock(ev.value);
                               }} />
                      </div>
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Unlock Duration</div>
                      <DropDownList
                        data={UnlockDuration}
                        textField="text"
                        dataItemKey="value"
                        value={doorDTMFMomentaryUnlockDuration}
                        onChange={(ev) => setDoorDTMFMomentaryUnlockDuration(ev.value)}
                      />
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Play Tone on “Momentary Unlock” Code Entry</div>
                      <Checkbox label={'Enable'} value={doorDTMFPlayTone} onChange={(ev) => setDoorDTMFPlayTone(ev.value)}/>
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Hang-up after “Momentary Unlock” Code</div>
                      <Checkbox label={'Enable'} value={doorDTMFHangupAfterMomentaryCode} onChange={(ev) => setDoorHangupAfterMomentaryCode(ev.value)}/>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Allow Leaving Door Unlocked</div>
                      <Checkbox label={'Enable'} value={doorDTMFPermanentUnlockEnable} onChange={(ev) => setDoorPermanentUnlockEnable(ev.value)}/>
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Leave Door Unlocked Code</div>
                      <div className='k-form-field-wrap'>
                        <Input type='text' value={doorDTMFPermanentUnlockCode}
                               onChange={(ev) => {
                                 setDoorPermanentUnlockCode(ev.value);
                               }} />
                      </div>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Leave Door Locked Code</div>
                      <div className='k-form-field-wrap'>
                        <Input type='text' value={doorDTMFPermanentLockCode}
                               onChange={(ev) => {
                                 setDoorPermanentLockCode(ev.value);
                               }} />
                      </div>
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Play Tone on “Leave Door Locked / Unlocked” Code Entry</div>
                      <Checkbox label={'Enable'} value={doorDTMFPlayToneAfterPermanentCode} onChange={(ev) => setPlayToneAfterPermanentCode(ev.value)}/>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Hang-up after “Leave Door Locked / Unlocked” Code Entry</div>
                      <Checkbox label={'Enable'} value={doorDTMFHangupAfterPermanentCode} onChange={(ev) => setPlayHangupAfterPermanentCode(ev.value)}/>
                    </div>
                  </div>
                </div>
                :
                <div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Momentary Unlock Code'} value={doorDTMFMomentaryUnlock}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Unlock Duration'} value={doorDTMFMomentaryUnlockDuration?.text}></DefaultViewControl>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl checkboxView={true} checkboxLabel={'Enable'} label={'Play Tone on “Momentary Unlock” Code Entry'} value={doorDTMFPlayTone}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl checkboxView={true} checkboxLabel={'Enable'} label={'Hang-up after “Momentary Unlock” Code'} value={doorDTMFHangupAfterMomentaryCode}></DefaultViewControl>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl checkboxView={true} checkboxLabel={'Enable'} label={'Allow Leaving Door Unlocked'} value={doorDTMFPermanentUnlockEnable}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Leave Door Unlocked Code'} value={doorDTMFPermanentUnlockCode}></DefaultViewControl>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Leave Door Locked Code'} value={doorDTMFPermanentLockCode}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl checkboxView={true} checkboxLabel={'Enable'} label={'Play Tone on “Leave Door Locked / Unlocked” Code Entry'} value={doorDTMFPlayToneAfterPermanentCode}></DefaultViewControl>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl checkboxView={true} checkboxLabel={'Enable'} label={'Hang-up after “Leave Door Locked / Unlocked” Code Entry'} value={doorDTMFHangupAfterPermanentCode}></DefaultViewControl>
                    </div>
                  </div>
                </div>
              }
            </ExpansionPanelContent>
          )}
        </div>
      </ExpansionPanel>
      <ExpansionPanel
        title={'Door Sensor'}
        expanded={expanded.thirdPanel}
        tabIndex={2}
        onAction={(event: ExpansionPanelActionEvent) => {
          setExpanded({...expanded, thirdPanel: !expanded.thirdPanel});
        }}
      >
        <div>
          {expanded.thirdPanel}
          {expanded.thirdPanel && (
            <ExpansionPanelContent>
              {props.editMode ?
                <div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Door Sensor</div>
                      <Checkbox label={'Installed'} value={doorSensorEnable} onChange={(ev) => setSensorEnable(ev.value)}/>
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Sensor Type</div>
                      <DropDownList
                        data={SensorType}
                        textField="text"
                        dataItemKey="value"
                        value={doorSensorType}
                        onChange={(ev) => setSensorType(ev.value)}
                      />
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Finish momentary unlock early when door is opened</div>
                      <Checkbox label={'Enable'} value={doorSensorRelockWhenOpened} onChange={(ev) => setDoorSensorRelockWhenOpened(ev.value)}/>
                    </div>
                  </div>
                </div>
                :
                <div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl checkboxView={true} checkboxLabel={'Installed'} label={'Door Sensor'} value={doorSensorEnable}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Sensor Type'} value={doorSensorType?.text}></DefaultViewControl>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl checkboxView={true} checkboxLabel={'Enable'} label={'Finish momentary unlock early when door is opened'} value={doorSensorRelockWhenOpened}></DefaultViewControl>
                    </div>
                  </div>
                </div>
              }
            </ExpansionPanelContent>
          )}
        </div>
      </ExpansionPanel>
      <ExpansionPanel
        title={'Door Ajar Alarm'}
        expanded={expanded.fourthPanel}
        tabIndex={3}
        onAction={(event: ExpansionPanelActionEvent) => {
          setExpanded({...expanded, fourthPanel: !expanded.fourthPanel});
        }}
      >
        <div>
          {expanded.fourthPanel}
          {expanded.fourthPanel && (
            <ExpansionPanelContent>
              {props.editMode ?
                <div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Door Ajar Alarm</div>
                      <Checkbox label={'Installed'} value={doorSensorAlarmEnable} onChange={(ev) => setSensorAlarmEnable(ev.value)}/>
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Alarm Period</div>
                      <DropDownList
                        data={AlarmPeriod}
                        textField="text"
                        dataItemKey="value"
                        value={doorSensorAlarmTimeout}
                        onChange={(ev) => setSensorAlarmTimeout(ev.value)}
                      />
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Phone number to dial</div>
                      <div className='k-form-field-wrap'>
                        <Input type='text' value={doorSensorAlarmDialNumber}
                               onChange={(ev) => {
                                 setSensorAlarmDialNumber(ev.value);
                               }} />
                      </div>
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Repeat Alarm Every</div>
                      <DropDownList
                        data={AlarmPeriod}
                        textField="text"
                        dataItemKey="value"
                        value={doorSensorAlarmDialRetry}
                        onChange={(ev) => setSensorAlarmDialRetry(ev.value)}
                      />
                    </div>
                  </div>
                </div>
                :
                <div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl checkboxView={true} checkboxLabel={'Installed'} label={'Door Ajar Alarm'} value={doorSensorAlarmEnable}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Alarm Period'} value={doorSensorAlarmTimeout?.text}></DefaultViewControl>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Phone number to dial'} value={doorSensorAlarmDialNumber}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Repeat Alarm Every'} value={doorSensorAlarmDialRetry?.text}></DefaultViewControl>
                    </div>
                  </div>
                </div>
              }
            </ExpansionPanelContent>
          )}
        </div>
      </ExpansionPanel>
      {props.editMode ?
        <div className={styles.actionButtonsBox + ' ' + 'k-form-buttons'}>
          <button
            type={'button'}
            className={styles.actionMainButton}
            onClick={() => save()}
          >
            Save
          </button>
          <button
            type={'button'}
            className={styles.actionButtonGrayWithStroke}
            onClick={() => cancel()}
          >
            Cancel
          </button>
        </div> : null
      }
    </div>
  );
};

export default IntercomConfigDoor;
