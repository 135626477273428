import { FunctionComponent, useEffect, useState } from 'react';
import styles from './IntercomDetailDialog.module.scss';
import { Dialog } from '@progress/kendo-react-dialogs';
import { apiClient, notifications } from '../../../services';
import { AxiosResponse } from 'axios/index';

interface Props {
  intercomWanMac: string,
  showDialogHandler: any;
}

const IntercomDetailDialog: FunctionComponent<Props> = (props: Props) => {
  const [configData, setConfigData] = useState<string>('');
  const closeDialog = () => {
    props.showDialogHandler(false)
  }

  useEffect(() => {
    apiClient().intercoms.getDetailByWanMac(props.intercomWanMac).then((response: AxiosResponse<any>) => {
      const jsonObject = JSON.parse(response.data);
      const formattedJsonString = JSON.stringify(jsonObject, null, 8);

      setConfigData(formattedJsonString);
    })
  }, [])

  const copyValue = () => {
    const textArea = document.createElement('textarea');
    textArea.value = configData;
    textArea.style.position = 'fixed';
    textArea.style.left = '-9999px';
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand('copy');
      notifications.success('Data was copied.');
    } catch (err) {
      notifications.error('Error: Data not copied.');
    }
    document.body.removeChild(textArea);
    closeDialog();
  }

  return (
    <Dialog width={922} height={'75%'} title='Intercom Detail' onClose={closeDialog}>
      <div id='intercomDetailJson' className={styles.modalBodyBorder}>
        {configData}
      </div>
      <div className={styles.modalActionBar}>
        <button className={styles.actionMainButton} onClick={copyValue}>Copy</button>
        <button className={styles.actionButtonGray} onClick={closeDialog}>Cancel</button>
      </div>
    </Dialog>
  )
}

export default IntercomDetailDialog;
