export interface Intercom {
  WanMac: string,
  Firmware: string,
  LastActivity: string,
  SipAddress: string,
  Status: IntercomStatus,
  UpgradeStep?: string,
}

export interface IUpgradeActionIntercom {
  WanMac?: string,
  Sip?: string,
  // firmware's id
  Id?: number,
}

export enum IntercomStatus
{
  Ready,
  UpgradeInProgress,
  UpgradeFailed,
  NotAvailable,
  RebootStarted,
  RebootFailed,
}

export const IntercomAvailableStatusList = [
  IntercomStatus.Ready,
  IntercomStatus.UpgradeFailed,
  IntercomStatus.RebootFailed
]
