import { combineReducers } from 'redux';

import appSettingsReducer from '../features/app-settings/app-settings-slice';
import userProfileReducer from '../features/auth/user-slice';
import intercomReducer from '../features/intercom/intercom-slice';

export const rootReducer = combineReducers({
  appSettings: appSettingsReducer,
  userProfile: userProfileReducer,
  intercom: intercomReducer,
});
