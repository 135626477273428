import { FunctionComponent, useEffect, useState } from 'react';
import { ConfigContentPropsManagementInterface } from '../ConfigContentPropsInterface';
import styles from '../IntercomConfigMenuContent.module.scss';
import { ExpansionPanel, ExpansionPanelActionEvent, ExpansionPanelContent } from '@progress/kendo-react-layout';
import DragNDrop from '../../../../../components/common/DragNDrop/DragNDrop';
import { useNavigate } from 'react-router-dom';
import { RouteConfig } from '../../../../../routes';

const routeConfig = RouteConfig();
const IntercomConfigManagement: FunctionComponent<ConfigContentPropsManagementInterface> = (props: ConfigContentPropsManagementInterface) => {
  const [expanded, setExpanded] = useState({ firstPanel: true });
  const navigate = useNavigate();
  const [files, setFiles] = useState<any>([]);
  const [fileInfo, setFileInfo] = useState<{ fileName: string, result: any }>({ fileName: '', result: null });

  if (!props.intercomId) {
    return (
      <></>
    );
  }

  const cancel = () => {
    props.cancelEvent();
  };

  const restoreConfiguration = () => {
    if (files && files[0]) {
      new Response(files[0]).json().then(json => {
        let updateConfigModel = { Config: json, IsPartial: false };
        props.saveEvent(updateConfigModel);
      }, err => {
        console.log('Error from json parsing: ', err)
      })
    }
  }

  return (
    <>
      {props.onlyRestoreViewMode
        ?
        <div className={styles.panelRowSpaceBetweenCustom}>
          <div className={styles.viewWrapper}>
            <div className={styles.labelView}>Attention! The configuration needs to be restored.</div>
            <div className={styles.panelRowSpaceBetween} style={{gap: 10}}>
              <DragNDrop onFilesSelected={setFiles}
                         viewMode={1}
                         width={'100%'}
                         acceptsFormats={'application/json'}
                         onSetFileInfo={setFileInfo} />
              <button
                type={'button'}
                style={{padding: 20}}
                className={styles.actionMainButton}
                disabled={files.length === 0}
                onClick={() => restoreConfiguration()}
              >
                Restore Configuration
              </button>
              <button
                type={'button'}
                style={{marginLeft: 15, padding: 20}}
                className={styles.actionButtonGrayWithStroke}
                onClick={() => navigate(routeConfig.Intercoms.path)}
              >
                Back
              </button>
            </div>
          </div>
        </div>

        :
        <div className={ props.model ? styles.pageWrapperIntercomConfigContent : styles.pageWrapperIntercomConfigContentManagement}
             id={'IntercomConfigContentExpander'}>
          <div className={styles.panelRowSpaceBetween} style={{ margin: 10 }}>
            <div className={styles.pageTitle}>Management</div>
          </div>
          <ExpansionPanel
            title={'Configuration'}
            expanded={expanded.firstPanel}
            tabIndex={0}
            onAction={(event: ExpansionPanelActionEvent) => {
              setExpanded({ ...expanded, firstPanel: !expanded.firstPanel });
            }}
          >
            <div>
              {expanded.firstPanel}
              {expanded.firstPanel && (
                <ExpansionPanelContent>
                  {props.editMode ?
                    <div className={styles.panelRowSpaceBetweenCustom}>
                      <div className={styles.viewWrapper}>
                        <div className={styles.labelView}>Restore</div>
                        <div className={styles.panelRowSpaceBetween} style={{gap: 10}}>
                          <DragNDrop onFilesSelected={setFiles}
                                     viewMode={1}
                                     width={'100%'}
                                     acceptsFormats={'application/json'}
                                     onSetFileInfo={setFileInfo} />
                          <button
                            type={'button'}
                            style={{padding: 20}}
                            className={styles.actionMainButton}
                            disabled={files.length === 0}
                            onClick={() => restoreConfiguration()}
                          >
                            Restore Configuration
                          </button>
                        </div>
                      </div>
                    </div>
                    :
                    <div className={styles.panelRowSpaceBetweenCustom}>
                      <div>
                        <button
                          type={'button'}
                          className={styles.actionMainButton}
                          onClick={() => restoreConfiguration()}
                          disabled={true}
                        >
                          Restore Configuration
                        </button>
                      </div>
                    </div>
                  }
                </ExpansionPanelContent>
              )}
            </div>
          </ExpansionPanel>
          {props.editMode ?
            <div className={styles.actionButtonsBox + ' ' + 'k-form-buttons'}>
              <button
                type={'button'}
                className={styles.actionButtonGrayWithStroke}
                onClick={() => cancel()}
              >
                Cancel
              </button>
            </div> : null
          }
        </div>
      }
    </>

  );
};

export default IntercomConfigManagement;
