import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../store';
import { Intercom } from '../../model/interfaces/intercom/intercom';

export interface IIntercomState {
  loading: boolean;
  data: Intercom | null;
}

const initialState: IIntercomState = {
  loading: false,
  data: null,
};

const intercomSlice = createSlice({
  name: 'intercom',
  initialState: initialState,
  reducers: {
    setIntercomLoading(state) {
      state.loading = true;
    },
    setIntercom(state, action: PayloadAction<Intercom>) {
      state.loading = false;
      state.data = action.payload;
    }
  },
});

// Actions
export const intercomActions = intercomSlice.actions;

// Selectors
export const selectIntercomLoading = (state: RootState) => state.intercom.loading;
export const selectIntercom = (state: RootState) => state.intercom.data;

// Reducer
const intercomReducer = intercomSlice.reducer;
export default intercomReducer;
