import { FunctionComponent, useCallback, useEffect, useReducer, useState } from 'react';
import { ConfigContentPropsInterface } from '../ConfigContentPropsInterface';
import styles from '../IntercomConfigMenuContent.module.scss';
import { ExpansionPanel, ExpansionPanelActionEvent, ExpansionPanelContent } from '@progress/kendo-react-layout';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import DefaultViewControl from '../../../../../components/common/defaultViewControl/DefaultViewControl';

interface ICodec {
  advanced: any
  algorithm: string
  disabled: boolean
  id: number
  name: string
  preferred_default_list: any
  preferred_list: any
  version: any
}

const Volume = [
  { text: 'Mute', value: 0 },
  { text: '1 (softest)', value: 1 },
  { text: '2', value: 2 },
  { text: '3', value: 3 },
  { text: '4', value: 4 },
  { text: '5', value: 5 },
  { text: '6 (normal)', value: 6 },
  { text: '7', value: 7 },
  { text: '8', value: 8 },
  { text: '9 (loudest)', value: 9 },
];

const HighPassBlocker = [
  { text: 'DC blocker only (60 Hz)', value: 1 },
  { text: 'High-pass (292 Hz)', value: 0 },
];


const IntercomConfigAudio: FunctionComponent<ConfigContentPropsInterface> = (props: ConfigContentPropsInterface) => {
  const [expanded, setExpanded] = useState({
    firstPanel: true,
    secondPanel: true,
    thirdPanel: true,
  });
  const [intercomConfig, setIntercomConfig] = useState<any>();
  const [speakerVolume, setSpeakerVolume] = useState<any>();
  const [micVolume, setMicVolume] = useState<any>();
  const [callDCblocker, setCallDCblocker] = useState<any>();
  const [micBoost, setMicBoost] = useState<boolean>();
  const [callEchoCanceller, setCallEchoCanceller] = useState<boolean>();
  const [callNoiseReduction, setCallNoiseReduction] = useState<boolean>();
  const [multicastEnabled, setMulticastEnabled] = useState<boolean>();
  const [multicastChannel0label, setMulticastChannel0label] = useState<string>();
  const [multicastChannel0address, setMulticastChannel0address] = useState<string>();
  const [multicastChannel0port, setMulticastChannel0port] = useState<string>();
  const [codecAvailableList, setCodecAvailableList] = useState<any>();
  const [codecPreferredList, setCodecPreferredList] = useState<any>();
  const [selectedAvailableCodec, setSelectedAvailableCodec] = useState<ICodec>()
  const [selectedPreferredCodec, setSelectedPreferredCodec] = useState<ICodec>()

  const [, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(() => {
    setValues(props.model);
  }, []);
  if (!props.model) {
    return (
      <></>
    );
  }

  const setValues = (model: any) => {
    if (model && model.intercom && model.intercom.call && model.intercom.multicast && model.intercom.multicast.channel && model.codecs) {
      setIntercomConfig(model);
      const call = model.intercom.call;
      const multicast = model.intercom.multicast;
      const channel = model.intercom.multicast.channel[0];
      const codecs = model.codecs;
      setSpeakerVolume({ text: Volume.find((i) => i.value === call.speaker_volume)?.text, value: call.speaker_volume });
      setMicVolume({ text: Volume.find((i) => i.value === call.mic_volume)?.text, value: call.mic_volume });
      setCallDCblocker({ text: HighPassBlocker.find((i) => i.value === call.dc_blocker)?.text, value: call.dc_blocker });
      setMicBoost(call.mic_boost);
      setCallEchoCanceller(call.echo_canceller);
      setCallNoiseReduction(call.noise_reduction);
      setMulticastEnabled(multicast.enable);
      setMulticastChannel0label(channel.label);
      setMulticastChannel0address(channel.address);
      setMulticastChannel0port(channel.port);
      setCodecAvailableList(codecs.available_list);
      setCodecPreferredList(codecs.preferred_list);
      if (!codecs.available_list) {
        setCodecAvailableList([{codecs: [], name: 'default'}]);
      }
      if (!codecs.preferred_list) {
        setCodecAvailableList([{codecs: [], name: 'default'}]);
      }
    }
  };
  const save = () => {
    intercomConfig.intercom.call.speaker_volume = speakerVolume?.value;
    intercomConfig.intercom.call.mic_volume = micVolume?.value;
    intercomConfig.intercom.call.mic_boost = micBoost;
    intercomConfig.intercom.call.dc_blocker = callDCblocker?.value;
    intercomConfig.intercom.call.noise_reduction = callNoiseReduction;
    intercomConfig.intercom.call.echo_canceller = callEchoCanceller;


    intercomConfig.intercom.multicast.enable = multicastEnabled;
    intercomConfig.intercom.multicast.channel = [
      {
        address: multicastChannel0address,
        label: multicastChannel0label,
        port: multicastChannel0port,
      },
    ];

    intercomConfig.codecs = {
      preferred_list: codecPreferredList,
      available_list: codecAvailableList
    };

    let updateConfigModel = {Config: intercomConfig, IsPartial: false}
    props.saveEvent(updateConfigModel);
  };

  const cancel = () => {
    setValues(props.model);
    props.cancelEvent();
  };

  const swapElements = (array: any[], index1: number, index2: number) => {
    let temp = array[index1];
    array[index1] = array[index2];
    array[index2] = temp;
    return array;
  };

  const addCodec = (codec: ICodec | undefined) => {
    if (!codec) {
      return
    }
    codecAvailableList[0].codecs = codecAvailableList[0].codecs.filter((c: ICodec) => c.id !== codec.id);
    setCodecAvailableList(codecAvailableList);

    codecPreferredList[0].codecs.push(codec);
    setCodecPreferredList(codecPreferredList);
    forceUpdate();
  }

  const deleteCodec = (codec: ICodec | undefined) => {
    if (!codec) {
      return
    }

    codecPreferredList[0].codecs = codecPreferredList[0].codecs.filter((c: ICodec) => c.id !== codec.id);
    setCodecPreferredList(codecPreferredList);

    codecAvailableList[0].codecs.push(codec);
    setCodecAvailableList(codecAvailableList);
    forceUpdate();
  }

  const moveUpCodec = (codec: ICodec | undefined) => {
    if (!codec) {
      return
    }
    const index1 = codecPreferredList[0].codecs.indexOf(codec);
    if (index1 === 0) {
      return;
    }
    const index2 = index1 - 1;
    codecPreferredList[0].codecs = swapElements(codecPreferredList[0].codecs, index1, index2)
    setCodecPreferredList(codecPreferredList);
    forceUpdate();
  }

  const moveDownCodec = (codec: ICodec | undefined) => {
    if (!codec) {
      return
    }
    const index1 = codecPreferredList[0].codecs.indexOf(codec);
    if (index1 === codecPreferredList[0].codecs.length - 1) {
      return;
    }
    const index2 = index1 + 1;
    codecPreferredList[0].codecs = swapElements(codecPreferredList[0].codecs, index1, index2)
    setCodecPreferredList(codecPreferredList);
    forceUpdate();
  }



  return (
    <div className={styles.pageWrapperIntercomConfigContent} id={'IntercomConfigContentExpander'}>
      <div className={styles.panelRowSpaceBetween} style={{ margin: 10 }}>
        <div className={styles.pageTitle}>Audio</div>
      </div>
      <ExpansionPanel
        title={'Intercom Audio'}
        expanded={expanded.firstPanel}
        tabIndex={0}
        onAction={(event: ExpansionPanelActionEvent) => {
          setExpanded({ ...expanded, firstPanel: !expanded.firstPanel });
        }}
      >
        <div>
          {expanded.firstPanel}
          {expanded.firstPanel && (
            <ExpansionPanelContent>
              {props.editMode ?
                <div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Speaker Volume</div>
                      <DropDownList
                        data={Volume}
                        textField='text'
                        dataItemKey='value'
                        value={speakerVolume}
                        onChange={(ev) => setSpeakerVolume(ev.value)}
                      />
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Microphone Volume</div>
                      <DropDownList
                        data={Volume}
                        textField='text'
                        dataItemKey='value'
                        value={micVolume}
                        onChange={(ev) => setMicVolume(ev.value)}
                      />
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Microphone HighPass</div>
                      <DropDownList
                        data={HighPassBlocker}
                        textField='text'
                        dataItemKey='value'
                        value={callDCblocker}
                        onChange={(ev) => setCallDCblocker(ev.value)}
                      />
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Microphone Boost (Advanced)</div>
                      <Checkbox label={'Enable'} value={micBoost} onChange={(ev) => setMicBoost(ev.value)}> </Checkbox>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Echo Canceller</div>
                      <Checkbox label={'Enable'} value={callEchoCanceller} onChange={(ev) => setCallEchoCanceller(ev.value)}> </Checkbox>
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Noise Reduction</div>
                      <Checkbox label={'Enable'} value={callNoiseReduction} onChange={(ev) => setCallNoiseReduction(ev.value)}> </Checkbox>
                    </div>
                  </div>
                </div>
                :
                <div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Speaker Volume'} value={speakerVolume?.text}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Microphone Volume'} value={micVolume?.text}></DefaultViewControl>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Microphone HighPass'} value={callDCblocker?.text}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl checkboxView={true} checkboxLabel={'Enable'} label={'Microphone Boost (Advanced)'}
                                          value={micBoost}></DefaultViewControl>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl checkboxView={true} checkboxLabel={'Enable'} label={'Echo Canceller'}
                                          value={callEchoCanceller}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl checkboxView={true} checkboxLabel={'Enable'} label={'Noise Reduction'}
                                          value={callNoiseReduction}></DefaultViewControl>
                    </div>
                  </div>
                </div>
              }
            </ExpansionPanelContent>
          )}
        </div>
      </ExpansionPanel>
      <ExpansionPanel
        title={'Background Audio'}
        expanded={expanded.secondPanel}
        tabIndex={0}
        onAction={(event: ExpansionPanelActionEvent) => {
          setExpanded({ ...expanded, secondPanel: !expanded.secondPanel });
        }}
      >
        <div>
          {expanded.secondPanel}
          {expanded.secondPanel && (
            <ExpansionPanelContent>
              {props.editMode ?
                <>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Status</div>
                      <Checkbox label={'Enable'} value={multicastEnabled} onChange={(ev) => setMulticastEnabled(ev.value)}> </Checkbox>
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Label</div>
                      <div className='k-form-field-wrap'>
                        <Input type='text' value={multicastChannel0label}
                               onChange={(ev) => {
                                 setMulticastChannel0label(ev.value);
                               }} />
                      </div>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>IP Address</div>
                      <div className='k-form-field-wrap'>
                        <Input type='text' value={multicastChannel0address}
                               onChange={(ev) => {
                                 setMulticastChannel0address(ev.value);
                               }} />
                      </div>
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Port</div>
                      <div className='k-form-field-wrap'>
                        <Input type='text' value={multicastChannel0port}
                               onChange={(ev) => {
                                 setMulticastChannel0port(ev.value);
                               }} />
                      </div>
                    </div>
                  </div>
                </>

                :
                <>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl checkboxView={true} checkboxLabel={'Enable'} label={'Status'} value={multicastEnabled}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Label'} value={multicastChannel0label}></DefaultViewControl>
                    </div>
                  </div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'IP Address'} value={multicastChannel0address}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Port'} value={multicastChannel0port}></DefaultViewControl>
                    </div>
                  </div>
                </>
              }
            </ExpansionPanelContent>
          )}
        </div>
      </ExpansionPanel>
      <ExpansionPanel
        title={'Codec Selection'}
        expanded={expanded.thirdPanel}
        tabIndex={2}
        onAction={(event: ExpansionPanelActionEvent) => {
          setExpanded({ ...expanded, thirdPanel: !expanded.thirdPanel });
        }}
      >
        <div>
          {expanded.thirdPanel}
          {expanded.thirdPanel && (
            <ExpansionPanelContent>
              {props.editMode ?
                <div className={styles.panelRowSpaceBetweenCustom}>
                  <div className={styles.viewWrapper}>
                    <div className={styles.labelView}>Available</div>
                    <div className={styles.codecSelectionBox}>
                      {codecAvailableList && codecAvailableList.length > 0 ? codecAvailableList[0].codecs.map((c: ICodec) => {
                        return(
                          <div className={styles.codecRow}
                               key={c.id}
                               style={selectedAvailableCodec?.id === c.id ? {backgroundColor: '#adddff'} : undefined}
                               onClick={() => setSelectedAvailableCodec(c)}>
                            {c.name}
                          </div>
                        )
                      }) : null}
                    </div>
                    <div className={styles.panelRowCenterCustom} style={{marginTop: 10}}>
                      <button type={'button'}
                              className={styles.actionButtonGrayWithStroke}
                              onClick={() => addCodec(selectedAvailableCodec)}>{'Enable >>'}</button>
                    </div>
                  </div>
                  <div className={styles.viewWrapper}>
                    <div className={styles.labelView}>Preferred</div>
                    <div className={styles.codecSelectionBox}>
                      {codecPreferredList && codecPreferredList.length > 0 ? codecPreferredList[0].codecs.map((c: ICodec) => {
                        return(
                          <div className={styles.codecRow}
                               key={c.id}
                               style={selectedPreferredCodec?.id === c.id ? {backgroundColor: '#adddff'} : undefined}
                               onClick={() => setSelectedPreferredCodec(c)}>
                            {c.name}
                          </div>
                        )
                      }): null}
                    </div>
                    <div className={styles.panelRowCenterCustom} style={{marginTop: 10}}>
                      <button type={'button'}
                              disabled={!selectedPreferredCodec}
                              className={styles.actionButtonGrayWithStroke}
                              onClick={() => deleteCodec(selectedPreferredCodec)}>{'<< Disable'}</button>
                      <button type={'button'}
                              disabled={!selectedPreferredCodec}
                              className={styles.actionButtonGrayWithStroke}
                              onClick={() => moveUpCodec(selectedPreferredCodec)}>Move Up</button>
                      <button type={'button'}
                              disabled={!selectedPreferredCodec}
                              className={styles.actionButtonGrayWithStroke}
                              onClick={() => moveDownCodec(selectedPreferredCodec)}>Move Down</button>
                    </div>
                  </div>
                </div>
                :
                <div className={styles.panelRowSpaceBetweenCustom}>
                  <div className={styles.viewWrapper}>
                    <div className={styles.labelView}>Available</div>
                    <div className={styles.codecSelectionBox}>
                      {codecAvailableList && codecAvailableList.length > 0 ? codecAvailableList[0].codecs.map((c: ICodec) => {
                        return(
                          <div key={c.id} className={styles.codecRow}>
                            {c.name}
                          </div>
                        )
                      }) : null}
                    </div>
                  </div>
                  <div className={styles.viewWrapper}>
                    <div className={styles.labelView}>Preferred</div>
                    <div className={styles.codecSelectionBox}>
                      {codecPreferredList && codecPreferredList.length > 0 ? codecPreferredList[0].codecs.map((c: ICodec) => {
                        return(
                          <div key={c.id} className={styles.codecRow}>
                            {c.name}
                          </div>
                        )
                      }): null}
                    </div>
                  </div>
                </div>
              }
            </ExpansionPanelContent>
          )}
        </div>
      </ExpansionPanel>

      {props.editMode ?
        <div className={styles.actionButtonsBox + ' ' + 'k-form-buttons'}>
          <button
            type={'button'}
            className={styles.actionMainButton}
            onClick={() => save()}
          >
            Save
          </button>
          <button
            type={'button'}
            className={styles.actionButtonGrayWithStroke}
            onClick={() => cancel()}
          >
            Cancel
          </button>
        </div> : null
      }
    </div>
  );
};

export default IntercomConfigAudio;
