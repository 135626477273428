import { FunctionComponent } from 'react';
import { IntercomStatus } from '../../../model/interfaces/intercom/intercom';
import FWUpgradeErrorIcon from '../../../assets/icons/FWUpgradeErrorIcon.svg';
import FWUpgradeProgressIcon from '../../../assets/icons/FWUpgradeProgressIcon.svg';
interface Props {
  status: IntercomStatus | undefined
}

const IntercomStatusView: FunctionComponent<Props> = (props: Props) => {
  switch (props.status) {
    case IntercomStatus.Ready:
      return null;
    case IntercomStatus.UpgradeInProgress:
      return (<img src={FWUpgradeProgressIcon} title={'Firmware upgrade in progress'}></img>)
    case IntercomStatus.UpgradeFailed:
      return (<img src={FWUpgradeErrorIcon} title={'Error: Firmware upgrade process unsuccessful.'}></img>)
    case IntercomStatus.NotAvailable:
      return (<img src={FWUpgradeErrorIcon} title={'Error: Firmware is not available.'}></img>)
    case IntercomStatus.RebootStarted:
      return (<img src={FWUpgradeProgressIcon} title={'Reboot started...'}></img>)
    case IntercomStatus.RebootFailed:
      return (<img src={FWUpgradeErrorIcon} title={'Reboot failed.'}></img>)
    default:
      return null;
  }
}

export default IntercomStatusView;
