import React, { createContext, useEffect, useState, ReactNode } from 'react';
import signalRService from './signalRService';
import { aqcuireToken } from '../../features';
import { getScopes } from '../api';

interface SignalRContextProps {
  signalRService: typeof signalRService;
  connected: boolean;
}

export const SignalRContext = createContext<SignalRContextProps>({
  signalRService,
  connected: false,
});

interface SignalRProviderProps {
  children: ReactNode;
}

export const SignalRProvider: React.FC<SignalRProviderProps> = ({ children }) => {
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    (async () => {
      let token = await aqcuireToken(getScopes());
      const start = (async () => {
        try {
          await signalRService.startConnection(token);
          setConnected(true);
        } catch (error) {
          console.error('Error connecting to SignalR', error);
        }
      });

      start();

      return () => {
        signalRService.stopConnection();
      };
    })();
  }, []);

  return (
    <SignalRContext.Provider value={{ signalRService, connected }}>
      {children}
    </SignalRContext.Provider>
  );
};
