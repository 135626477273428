export interface IntercomDialogConfig {
  title: string,
  isUpgrade: boolean,
  mainButtonName: string;
  type: IntercomDialogEnum;
}

export enum IntercomDialogEnum {
  Default, VendGate, OpenGate, CloseGate, Upgrade, Reboot, Detail
}
