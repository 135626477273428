import { FunctionComponent, useContext, useEffect, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { apiClient, notifications, UpgradeConfigMode } from '../../../services';
import styles from './IntercomInfoPage.module.scss';
import InfoCard from '../../../components/common/InfoCard/InfoCard';
import { AxiosResponse } from 'axios/index';
import IntercomConfigMenu, { IMenuItem } from './intercomConfigMenu/IntercomConfigMenu';
import IntercomConfigCalls from './IntercomConfigMenuContentPages/IntercomCategory/IntercomConfigCalls';
import IntercomConfigButtons from './IntercomConfigMenuContentPages/IntercomCategory/IntercomConfigButtons';
import IntercomConfigDoor from './IntercomConfigMenuContentPages/IntercomCategory/IntercomConfigDoor';
import IntercomConfigAccounts from './IntercomConfigMenuContentPages/IntercomCategory/IntercomConfigAccounts';
import IntercomConfigAudio from './IntercomConfigMenuContentPages/IntercomCategory/IntercomConfigAudio';
import IntercomConfigNetwork from './IntercomConfigMenuContentPages/IntercomCategory/IntercomConfigNetwork';
import IntercomConfigManagement from './IntercomConfigMenuContentPages/IntercomCategory/IntercomConfigManagement';
import EditIcon from '../../../assets/icons/editIcon.svg';
import DownloadIcon from '../../../assets/icons/downloadIcon.svg';
import { JSONToFileService } from '../../../services/jsonToFile-service';
import IntercomConfigSystem from './IntercomConfigMenuContentPages/IntercomCategory/IntercomConfigSystem';
import { Dialog } from '@progress/kendo-react-dialogs';
import { SignalRContext } from '../../../services/signalR/signalRContext';
import { Intercom, IntercomAvailableStatusList, IntercomStatus } from '../../../model/interfaces/intercom/intercom';
import VendGateIcon from '../../../assets/icons/VendGateIcon.svg';
import { IntercomDialogEnum } from '../../../model/common/intercomDialogConfig';
import OpenGateIcon from '../../../assets/icons/OpenGateIcon.svg';
import CloseGateIcon from '../../../assets/icons/CloseGateIcon.svg';
import UpgradeIcon from '../../../assets/icons/UpgradeIcon.svg';
import DetailsIcon from '../../../assets/icons/DetailsIcon.svg';
import RebootIcon from '../../../assets/icons/RebootIcon.svg';
import IntercomActions from '../IntercomActions/intercomActions';
import IntercomStatusView from '../IntercomStatusView/IntercomStatusView';

const MenuItems: IMenuItem[] = [
  { id: 1, name: 'Call' },
  { id: 2, name: 'Buttons / Light' },
  { id: 3, name: 'Door' },
  { id: 4, name: 'Accounts' },
  { id: 5, name: 'Audio' },
  { id: 6, name: 'Network' },
  { id: 7, name: 'System' },
  { id: 8, name: 'Management' },
];
const defaultMenuItem = { id: 1, name: 'Call' };
let _lastUpdatedCurrentIntercom: Intercom | null = null;


const IntercomInfoPage: FunctionComponent = () => {
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const currentLoc = useLocation();
  const intercomId: string = currentLoc.pathname.split('/')[2];
  const [commandName, setCommandName] = useState<IntercomDialogEnum>(IntercomDialogEnum.Default);
  const [intercomConfig, setIntercomConfig] = useState<any>();
  const [selectedMenuItem, setSelectedMenuItem] = useState<any>(defaultMenuItem);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [viewPage, setViewPage] = useState<boolean>(false);
  const [showIntercomActonDialog, setShowIntercomActonDialog] = useState<boolean>(false);

  const [doorStatus, setDoorStatus] = useState<any>();
  const [user, setUser] = useState<any>();
  const [system, setSystem] = useState<any>();
  const [fullUpdateWaitingModalShow, setFullUpdateWaitingModalShow] = useState<boolean>(false);
  const [waitingModalMessage, setWaitingModalMessage] = useState<string>('');
  const { signalRService, connected } = useContext(SignalRContext);

  useEffect(() => {

    apiClient().intercoms.getIntercomByWanMac(intercomId).then((response: AxiosResponse<any>) => {
      _lastUpdatedCurrentIntercom = response.data;
      if (_lastUpdatedCurrentIntercom && IntercomAvailableStatusList.includes(_lastUpdatedCurrentIntercom.Status)) {
        apiClient().intercoms.getConfigById(intercomId).then((response: AxiosResponse<any>) => {
          prepareData(response.data);
        });
      }
    });
  }, []);

  useEffect(() => {
    if (connected) {
      const handleNewMessage = (newIntercom: any) => {
        if (_lastUpdatedCurrentIntercom && newIntercom.wanMac === _lastUpdatedCurrentIntercom.WanMac) {
          _lastUpdatedCurrentIntercom = {
            Firmware: newIntercom.firmware,
            WanMac: newIntercom.wanMac,
            LastActivity: newIntercom.lastActivity,
            SipAddress: newIntercom.sipAddress,
            Status: newIntercom.status,
            UpgradeStep: newIntercom.upgradeStep,
          };
          forceUpdate();
        }
      };

      signalRService.subscribe('IntercomStatusChanged', handleNewMessage);

      return () => {
        signalRService.unsubscribe('IntercomStatusChanged', handleNewMessage);
      };
    }
  }, [connected, signalRService]);

  const selectMenuItem = (event: any) => {
    setSelectedMenuItem(event);
  };

  const updateIntercomConfig = (updatedPartOfIntercomConfig: UpgradeConfigMode) => {
    if (updatedPartOfIntercomConfig) {
      apiClient().intercoms.updateConfig(intercomId, updatedPartOfIntercomConfig).then((response) => {
        if (response.data) {
          notifications.success();
          setEditMode(false);
          if (updatedPartOfIntercomConfig.IsPartial) {
            apiClient().intercoms.getConfigById(intercomId).then((response: AxiosResponse<any>) => {
              prepareData(response.data);
            });
          }
          if (!updatedPartOfIntercomConfig.IsPartial) {
            setFullUpdateWaitingModalShow(true);
            setWaitingModalMessage('The intercom update is still in progress...');
            let interval = setInterval(() => {
              if (_lastUpdatedCurrentIntercom && _lastUpdatedCurrentIntercom.WanMac === intercomId
                && _lastUpdatedCurrentIntercom.Status === 0) {
                apiClient({ isPoolingMode: true }).intercoms.getConfigById(intercomId).then((response: AxiosResponse<any>) => {
                  prepareData(response.data);
                  setFullUpdateWaitingModalShow(false);
                  notifications.success('The configuration has been fully updated.');
                  clearInterval(interval);
                });
              }
            }, 5000);
          }
        }
      });
    }
  };

  const downloadIntercomConfig = () => {
    apiClient().intercoms.downloadConfig(intercomId).then((data) => {
      JSONToFileService.JSONToFile(data.data, `intercom_config_${intercomId}`);
    });
  };

  const prepareData = (data: any) => {
    setIntercomConfig(data);
    if (data.intercom.door) {
      let doorStatusData = [
        { name: 'Lock', value: data.intercom.door.enable ? 'Permanently Locked' : 'Lock not installed' },
        { name: 'Door', value: data.intercom.door.permanent_state === 'locked' ? 'Closed' : 'Open' },
      ];
      setDoorStatus(doorStatusData);
    }

    let user = [
      { name: 'User', value: data.voip.accounts[0].user },
      { name: 'Status', value: data.voip.accounts[0].enable ? 'Open' : 'Closed' },
    ];
    setUser(user);

    let system = [
      { name: 'WAN', value: `${data.network.wan.mode}` },
      { name: 'MAC Address', value: intercomId },
    ];

    setSystem(system);
    setViewPage(true);
  };

  const disableButtonStatus = () => {
    if (_lastUpdatedCurrentIntercom) {
      return editMode || !IntercomAvailableStatusList.includes(_lastUpdatedCurrentIntercom.Status)
    } else {
      return false;
    }
  }

  const setActionDialog = (commandName: number) => {
    setCommandName(commandName);
    setShowIntercomActonDialog(true);
  }

  return (
    <>
      {viewPage
        ?
        <div className={styles.pageWrapper + ' ' + styles.pageWrapperIntercom}>
          {fullUpdateWaitingModalShow ?
            <>
              <Dialog width={400} height={'15%'} title='Please wait.'>
                <div className={styles.modalBodyBorder}>
                  {waitingModalMessage}
                </div>
              </Dialog>
            </>

            : null}
          {intercomConfig ?
            <>
              {_lastUpdatedCurrentIntercom ? (
                <div className={styles.panelRowSpaceBetween} style={{ marginBottom: 20 }}>
                  <div className={styles.panelRowSpaceBetween} style={{ gap: 10 }}>
                    <div className={styles.pageTitle}>Intercom {intercomId}</div>
                    <div className={styles.statusIndicator}>
                      <IntercomStatusView status={_lastUpdatedCurrentIntercom.Status}></IntercomStatusView>
                      {_lastUpdatedCurrentIntercom.Status === 4 ?
                        <span style={{ marginLeft: 10 }}
                              className={styles.optionalText}>Intercom is rebooting, wait please....</span> : null}
                      {_lastUpdatedCurrentIntercom.Status === 5 ?
                        <span style={{ marginLeft: 10 }} className={styles.optionalText}>Reboot failed.</span> : null}
                      {!!_lastUpdatedCurrentIntercom.UpgradeStep ? <span style={{ marginLeft: 10 }}
                                                                         className={styles.optionalText}> {_lastUpdatedCurrentIntercom.Status === 1 ? _lastUpdatedCurrentIntercom.UpgradeStep + '...' : _lastUpdatedCurrentIntercom.Status === 2 ? _lastUpdatedCurrentIntercom.UpgradeStep : ''}</span> : null}
                    </div>
                  </div>


                  <div className={styles.panelRowSpaceBetween} style={{ gap: 10 }}>
                    {showIntercomActonDialog ? <IntercomActions dataItem={_lastUpdatedCurrentIntercom}
                                                                sendClose={(event: boolean) => setShowIntercomActonDialog(!event)}
                                                                commandName={commandName}></IntercomActions> : null}
                    <div>
                      {!editMode ?
                        <div className={styles.panelRowSpaceBetween} style={{ gap: 20 }}>
                          <button className={styles.actionButton}
                                  disabled={!IntercomAvailableStatusList.includes(_lastUpdatedCurrentIntercom.Status)}
                                  onClick={downloadIntercomConfig}>
                            <img className={styles.buttonIconPosition} src={DownloadIcon} alt='downloadIcon' />
                            Download
                          </button>
                          <button className={styles.actionButton}
                                  disabled={!IntercomAvailableStatusList.includes(_lastUpdatedCurrentIntercom.Status)}
                                  onClick={() => setEditMode(true)}>
                            <img className={styles.buttonIconPosition} src={EditIcon} alt='edit' />
                            Edit
                          </button>
                        </div>
                        : null}
                    </div>
                  </div>


                </div>
              ) : null}
              <div className={styles.infoCardsGroup}>
                {doorStatus ? <InfoCard title={'Door Status'} data={doorStatus}></InfoCard> : null}
                {user ? <InfoCard title={'User'} data={user}></InfoCard> : null}
                {system ? <InfoCard title={'System'} data={system}></InfoCard> : null}
                <div className={styles.cardWrapper}>
                  <span className={styles.cardTitle}>Device Controls</span>
                  {_lastUpdatedCurrentIntercom ? (
                  <div>
                      <div className={styles.cardDataGroup}>
                        <button className={styles.actionButtonGray}
                                disabled={disableButtonStatus()}
                                title={disableButtonStatus() ? 'Vend Gate is blocked while Intercom updating' : ''}
                                onClick={() => setActionDialog(IntercomDialogEnum.VendGate)}>
                          <img className={styles.buttonIconPositionBig} src={VendGateIcon} alt='Vend Gate' />
                          Vend Gate
                        </button>
                        <button className={styles.actionButtonGray}
                                disabled={disableButtonStatus()}
                                title={disableButtonStatus() ? 'Open Gate is blocked while Intercom updating' : ''}
                                onClick={() => setActionDialog(IntercomDialogEnum.OpenGate)}>
                          <img className={styles.buttonIconPositionBig} src={OpenGateIcon} alt='Open Gate' />
                          Open Gate
                        </button>
                        <button className={styles.actionButtonGray}
                                disabled={disableButtonStatus()}
                                title={disableButtonStatus() ? 'Close Gate is blocked while Intercom updating' : ''}
                                onClick={() => setActionDialog(IntercomDialogEnum.CloseGate)}>
                          <img className={styles.buttonIconPositionBig} src={CloseGateIcon} alt='Close Gate' />
                          Close Gate
                        </button>
                        <button className={styles.actionButtonGray}
                                disabled={disableButtonStatus()}
                                title={disableButtonStatus() ? 'Upgrade is blocked while Intercom updating' : ''}
                                onClick={() => setActionDialog(IntercomDialogEnum.Upgrade)}>
                          <img className={styles.buttonIconPositionBig} src={UpgradeIcon} alt='Upgrade' />
                          Upgrade
                        </button>
                        <button className={styles.actionButtonGray}
                                disabled={disableButtonStatus()}
                                title={disableButtonStatus() ? 'Detail is blocked while Intercom updating' : ''}
                                onClick={() => setActionDialog(IntercomDialogEnum.Detail)}>
                          <img className={styles.buttonIconPositionBig} src={DetailsIcon} alt='Details' />
                          Details
                        </button>
                        <button className={styles.actionButtonGray}
                                disabled={disableButtonStatus()}
                                title={disableButtonStatus() ? 'Reset is blocked while Intercom updating' : ''}
                                onClick={() => setActionDialog(IntercomDialogEnum.Reboot)}>
                          <img className={styles.buttonIconPositionBig} src={RebootIcon} alt='Reboot' />
                          Reboot
                        </button>
                      </div>
                  </div>
                  ): null}
                </div>
              </div>
              <>
                { _lastUpdatedCurrentIntercom && IntercomAvailableStatusList.includes(_lastUpdatedCurrentIntercom.Status) ?

                  <div className={styles.infoPageContent}>
                    <IntercomConfigMenu eventItemSelected={selectMenuItem}
                                        defaultSelectedItem={defaultMenuItem}
                                        menuItems={MenuItems}></IntercomConfigMenu>
                    <div className={styles.infoPageMenuContent}>
                      {selectedMenuItem.id === 1 && intercomConfig?.intercom?.call ? <IntercomConfigCalls model={intercomConfig}
                                                                                                          cancelEvent={() => setEditMode(false)}
                                                                                                          saveEvent={(ev: any) => updateIntercomConfig(ev)}
                                                                                                          editMode={editMode}></IntercomConfigCalls> : null}
                      {selectedMenuItem.id === 2 ? <IntercomConfigButtons
                        model={intercomConfig}
                        cancelEvent={() => setEditMode(false)}
                        saveEvent={(ev: any) => updateIntercomConfig(ev)}
                        editMode={editMode}
                      ></IntercomConfigButtons> : null}
                      {selectedMenuItem.id === 3 ? <IntercomConfigDoor
                        model={intercomConfig?.intercom?.door}
                        cancelEvent={() => setEditMode(false)}
                        saveEvent={(ev: any) => updateIntercomConfig(ev)}
                        editMode={editMode}
                      ></IntercomConfigDoor> : null}
                      {selectedMenuItem.id === 4 ? <IntercomConfigAccounts
                        model={intercomConfig}
                        cancelEvent={() => setEditMode(false)}
                        saveEvent={(ev: any) => updateIntercomConfig(ev)}
                        editMode={editMode}
                      ></IntercomConfigAccounts> : null}
                      {selectedMenuItem.id === 5 ? <IntercomConfigAudio
                        model={intercomConfig}
                        cancelEvent={() => setEditMode(false)}
                        saveEvent={(ev: any) => updateIntercomConfig(ev)}
                        editMode={editMode}
                      ></IntercomConfigAudio> : null}
                      {selectedMenuItem.id === 6 ? <IntercomConfigNetwork
                        model={intercomConfig}
                        cancelEvent={() => setEditMode(false)}
                        saveEvent={(ev: any) => updateIntercomConfig(ev)}
                        editMode={editMode}
                      ></IntercomConfigNetwork> : null}
                      {selectedMenuItem.id === 7 ? <IntercomConfigSystem
                        model={intercomConfig?.system}
                        cancelEvent={() => setEditMode(false)}
                        saveEvent={(ev: any) => updateIntercomConfig(ev)}
                        editMode={editMode}
                      ></IntercomConfigSystem> : null}
                      {selectedMenuItem.id === 8 ? <IntercomConfigManagement
                        onlyRestoreViewMode={false}
                        model={intercomConfig}
                        intercomId={intercomId}
                        cancelEvent={() => setEditMode(false)}
                        saveEvent={(ev: any) => updateIntercomConfig(ev)}
                        editMode={editMode}></IntercomConfigManagement> : null}
                    </div>
                  </div>
                  :
                  <div className={styles.infoPageContentWhileNotReady}>
                    {_lastUpdatedCurrentIntercom && _lastUpdatedCurrentIntercom.Status === IntercomStatus.UpgradeInProgress
                      ? <div>The intercom is updating. Please wait until it completes.</div>
                    : null}
                    {_lastUpdatedCurrentIntercom && _lastUpdatedCurrentIntercom.Status === IntercomStatus.RebootStarted
                      ? <div>The intercom is rebooting. Please wait until it completes.</div>
                      : null}
                  </div>
                }
              </>
            </>

            :
            <div>
              <IntercomConfigManagement
                model={intercomConfig}
                intercomId={intercomId}
                onlyRestoreViewMode={true}
                cancelEvent={() => setEditMode(false)}
                saveEvent={(ev: any) => updateIntercomConfig(ev)}
                editMode={editMode}></IntercomConfigManagement>
            </div>

          }

        </div>
        :
        null}
    </>

  );
};

export default IntercomInfoPage;
