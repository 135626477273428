import * as signalR from "@microsoft/signalr";
import { getBaseUrl } from '../api';
import { HttpTransportType, LogLevel } from '@microsoft/signalr';
type EventHandler = (data: any) => void;

class SignalRService {
  private connection: signalR.HubConnection | null = null;
  private eventHandlers: Map<string, EventHandler[]> = new Map();

  async startConnection(token: string): Promise<void> {
    if (this.connection) {
      console.warn("SignalR connection is already established.");
      return;
    }
    const URL_HUB = getBaseUrl() + '/intercomHub';

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${URL_HUB}`, {
        accessTokenFactory: () => token,
        transport: HttpTransportType.LongPolling
      })
      .configureLogging(LogLevel.Error)
      .build();

    this.connection.onclose(() => {
      console.log("SignalR connection closed.");
      this.connection = null;
    });

    try {
      await this.connection.start();
      console.log("SignalR connected!");
    } catch (error) {
      console.error("Error while starting SignalR connection:", error);
      throw error;
    }
  }

  subscribe(event: string, callback: EventHandler): void {
    if (!this.eventHandlers.has(event)) {
      this.eventHandlers.set(event, []);
    }

    this.eventHandlers.get(event)?.push(callback);

    if (this.connection) {
      this.connection.on(event, (data: any) => {
        this.eventHandlers.get(event)?.forEach((cb) => cb(data));
      });
    }
  }

  unsubscribe(event: string, callback: EventHandler): void {
    const handlers = this.eventHandlers.get(event) || [];
    this.eventHandlers.set(
      event,
      handlers.filter((cb) => cb !== callback)
    );

    if (this.connection) {
      this.connection.off(event, callback);
    }
  }

  async stopConnection(): Promise<void> {
    if (this.connection) {
      await this.connection.stop();
      console.log("SignalR disconnected.");
      this.connection = null;
    }
  }
}

const signalRService = new SignalRService();
export default signalRService;
