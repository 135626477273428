import { FunctionComponent, useEffect, useState } from 'react';
import styles from '../IntercomConfigMenuContent.module.scss';
import { ExpansionPanel, ExpansionPanelActionEvent, ExpansionPanelContent } from '@progress/kendo-react-layout';
import { Reveal } from '@progress/kendo-react-animation';
import { IMenuItem } from '../../intercomConfigMenu/IntercomConfigMenu';
import DefaultViewControl from '../../../../../components/common/defaultViewControl/DefaultViewControl';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';
import DragNDrop from '../../../../../components/common/DragNDrop/DragNDrop';
import { Checkbox } from '@progress/kendo-react-inputs';
import { ConfigContentPropsInterface } from '../ConfigContentPropsInterface';


interface IRingLimit {
  text?: string,
  value?: number
}

const OutboundRingLimit: IRingLimit[] = [
  { text: '1 ring', value: 6 },
  { text: '2 ring', value: 12 },
  { text: '3 ring', value: 18 },
  { text: '4 ring', value: 24 },
  { text: '5 ring', value: 30 },
  { text: '6 ring', value: 36 },
  { text: 'No limit (ring until answered)', value: -1 },
];

const InboundRingLimit: IRingLimit[] = [
  { text: 'Answer immediately', value: 0 },
  { text: '1 ring', value: 6 },
  { text: '2 ring', value: 12 },
  { text: '3 ring', value: 18 },
  { text: '4 ring', value: 24 },
  { text: '5 ring', value: 30 },
  { text: '6 ring', value: 36 },
];

const IntercomConfigCalls: FunctionComponent<ConfigContentPropsInterface> = (props: ConfigContentPropsInterface) => {
  const [expanded, setExpanded] = useState({ firstPanel: true, secondPanel: true });
  const [intercomConfig, setIntercomConfig] = useState<any>();
  const [ringbackTone, setRingbackTone] = useState<string>();
  const [ringtone, setRingtone] = useState<string>();
  const [outboundRingLimit, setOutboundRingLimit] = useState<IRingLimit>();
  const [allowCallsToBeRedirected, setAllowCallsToBeRedirected] = useState<boolean>();
  const [answerInboundCalls, setAnswerInboundCalls] = useState<boolean>();
  const [inboundRingLimit, setInboundRingLimit] = useState<IRingLimit>();

  useEffect(() => {
    setValues(props.model);
  }, []);

  if (!props.model) {
    return (
      <></>
    );
  }

  const setValues = (intercomConfig: any) => {
    if (intercomConfig?.intercom?.call) {
      let intercomCall = intercomConfig.intercom.call;
      setIntercomConfig(intercomConfig);
      setOutboundRingLimit({
        text: OutboundRingLimit.find((i) => i.value === intercomCall.outbound_ring_limit)?.text,
        value: intercomCall.outbound_ring_limit,
      });
      setRingbackTone(intercomCall.ringback_tone);
      setRingtone(intercomCall.ringback_filename);
      setAllowCallsToBeRedirected(!!intercomCall.follow_redirect);
      setAnswerInboundCalls(!!intercomCall.answer_inbound_calls);
      setInboundRingLimit({
        text: InboundRingLimit.find((i) => i.value === intercomCall.inbound_ring_limit)?.text,
        value: intercomCall.inbound_ring_limit,
      });
    }
  };


  const save = () => {
    let updateModel = {
      intercom: {
        call: {
          outbound_ring_limit: outboundRingLimit?.value,
          ringback_tone: ringbackTone,
          ringback_filename: ringtone,
          follow_redirect: allowCallsToBeRedirected,
          answer_inbound_calls: answerInboundCalls,
          inbound_ring_limit: inboundRingLimit?.value,
        },
      },
    };
    let updateConfigModel = { Config: updateModel, IsPartial: true };
    props.saveEvent(updateConfigModel);
  };

  const cancel = () => {
    setValues(props.model);
    props.cancelEvent();
  };

  const handleChange = (event: DropDownListChangeEvent) => {
    setOutboundRingLimit({ text: event.value.text, value: event.value.value });
  };


  return (
    <div className={styles.pageWrapperIntercomConfigContent} id={'IntercomConfigContentExpander'}>
      <div className={styles.panelRowSpaceBetween} style={{ margin: 10 }}>
        <div className={styles.pageTitle}>Calls</div>
      </div>
      <ExpansionPanel
        title={'Outbound Calls'}
        expanded={expanded.firstPanel}
        tabIndex={0}
        onAction={(event: ExpansionPanelActionEvent) => {
          setExpanded({ ...expanded, firstPanel: !expanded.firstPanel });
        }}
      >
        <div>
          {expanded.firstPanel}
          {expanded.firstPanel && (
            <ExpansionPanelContent>
              {props.editMode ?
                <div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Outbound Ring Limit</div>
                      <DropDownList
                        data={OutboundRingLimit}
                        textField='text'
                        dataItemKey='value'
                        value={outboundRingLimit}
                        onChange={handleChange}
                      />
                    </div>
                    <div className={styles.viewWrapper}>
                      <div className={styles.labelView}>Allow calls to be redirected</div>
                      <Checkbox label={'Active'} value={allowCallsToBeRedirected}
                                onChange={(ev) => setAllowCallsToBeRedirected(ev.value)} />
                    </div>
                  </div>
                </div>
                :
                <div>
                  <div className={styles.panelRowSpaceBetweenCustom}>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl label={'Outbound Ring Limit'} value={outboundRingLimit?.text}></DefaultViewControl>
                    </div>
                    <div className={styles.viewWrapper}>
                      <DefaultViewControl checkboxView={true} checkboxLabel={'Active'} label={'Allow calls to be redirected'}
                                          value={allowCallsToBeRedirected}></DefaultViewControl>
                    </div>
                  </div>
                </div>
              }
            </ExpansionPanelContent>
          )}
        </div>
      </ExpansionPanel>
      <ExpansionPanel
        title={'Inbound Calls'}
        expanded={expanded.secondPanel}
        tabIndex={1}
        onAction={(event: ExpansionPanelActionEvent) => {
          setExpanded({ ...expanded, secondPanel: !expanded.secondPanel });
        }}
      >
        <div>
          {expanded.secondPanel}
          {expanded.secondPanel && (
            <ExpansionPanelContent>
              {props.editMode ?

                <div className={styles.panelRowSpaceBetweenCustom}>
                  <div className={styles.viewWrapper}>
                    <div className={styles.labelView}>Allow Inbound Calls</div>
                    <Checkbox label={'Active'} value={answerInboundCalls} onChange={(ev) => setAnswerInboundCalls(ev.value)} />
                  </div>
                  <div className={styles.viewWrapper}>
                    <div className={styles.labelView}>Inbound Ring Limit</div>
                    <DropDownList
                      data={InboundRingLimit}
                      textField='text'
                      dataItemKey='value'
                      value={inboundRingLimit}
                      onChange={(event) => setInboundRingLimit({ text: event.value.text, value: event.value.value })}
                    />
                  </div>
                </div>
                :
                <div className={styles.panelRowSpaceBetweenCustom}>
                  <div className={styles.viewWrapper}>
                    <DefaultViewControl checkboxView={true} checkboxLabel={'Active'} label={'Allow Inbound Calls'}
                                        value={answerInboundCalls}></DefaultViewControl>
                  </div>
                  <div className={styles.viewWrapper}>
                    <DefaultViewControl label={'Inbound Ring Limit'} value={inboundRingLimit?.text}></DefaultViewControl>
                  </div>
                </div>
              }
            </ExpansionPanelContent>
          )}
        </div>
      </ExpansionPanel>
      {props.editMode ?
        <div className={styles.actionButtonsBox + ' ' + 'k-form-buttons'}>
          <button
            type={'button'}
            className={styles.actionMainButton}
            onClick={() => save()}
          >
            Save
          </button>
          <button
            type={'button'}
            className={styles.actionButtonGrayWithStroke}
            onClick={() => cancel()}
          >
            Cancel
          </button>
        </div> : null
      }

    </div>
  );
};

export default IntercomConfigCalls;
