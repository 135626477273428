import { aqcuireToken } from '../../features';
import { notifications } from '../notifications-service';
import { store } from '../../store';
import axios, { AxiosInstance } from 'axios';

export const getApiUrl = (): string | undefined => {
  return store.getState().appSettings.data?.API_URL;
};

export const getBaseUrl = (): string | undefined => {
  return store.getState().appSettings.data?.BASE_URL;
};

export function getScopes(): string[] {
  const appSettings = store.getState().appSettings.data;
  if (!appSettings) {
    return [];
  }

  return [`api://${appSettings.aad.clientId}/Default`];
}

export function getAccessToken(): Promise<string> {
  return aqcuireToken(getScopes());
}

let _api: AxiosInstance | null = null;
let _isPoolingMode: boolean | null = null;

export const getApiForLoader = (): AxiosInstance => {
  return _api ? _api : getApi({isPoolingMode: false});
}

const getApi = ({isPoolingMode = false}): AxiosInstance => {
  _isPoolingMode = isPoolingMode;

  if (_api) {
    return _api;
  }

  const api: AxiosInstance = axios.create({
    baseURL: getApiUrl(),
  });

  api.interceptors.request.use(
    async (config: any) => {
      config.baseURL = getApiUrl();

      if (config.headers && !config.headers['Content-Type']) {
        config.headers['Content-Type'] = 'application/json';
      }

      const accessToken = await aqcuireToken(getScopes());
      if (config.headers && accessToken) {
        config.headers['Authorization'] = 'Bearer ' + accessToken;
      }

      return config;
    },
    (error: any) => {
      Promise.reject(error);
    },
  );

  api.interceptors.response.use(
    (next: any) => {
      return Promise.resolve(next);
    },
    (error: { message: string; }) => {
      if (!_isPoolingMode) {
        notifications.error(error.message);
      }
      return Promise.reject(error);
    },
  );

  _api = api;
  return api;
};

export default getApi;
