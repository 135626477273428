import { FieldWrapper } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';
import styles from '../../../../pages/intercoms/intercoms.module.scss';
import { TextArea } from '@progress/kendo-react-inputs';

const FormTextArea = (fieldRenderProps: any) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    ...others
  } = fieldRenderProps;

  const showValidationMessage = touched && validationMessage;

  return (
    <FieldWrapper>
      <Label
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
        optional={optional}
        className={styles.fieldLabel}
      >
        {label}
        <sup className={styles.requiredStar}></sup>
      </Label>
      <div className='k-form-field-wrap'>
        <TextArea
          maxLength={50}
          valid={valid}
          id={id}
          {...others}
        />
        {showValidationMessage && (
          <div className={styles.errorMessage}>{validationMessage}</div>
        )}
      </div>
    </FieldWrapper>
  );
};

export default FormTextArea;
