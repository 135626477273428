import { useConfirmDialog } from '../../../components/common/ConfirmDialog/ConfirmDialogContext';
import { useEffect, useState } from 'react';
import { IntercomDialogConfig, IntercomDialogEnum } from '../../../model/common/intercomDialogConfig';
import { apiClient, notifications } from '../../../services';
import { AxiosResponse } from 'axios';
import { Firmware } from '../../../model/interfaces/firmware';
import { Intercom, IUpgradeActionIntercom } from '../../../model/interfaces/intercom/intercom';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Field, FieldWrapper, Form, FormElement } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';
import styles from '../intercoms.module.scss';
import IntercomDetailDialog from '../IntercomDetailDialog/IntercomDetailDialog';
import FormTextArea from '../../../components/common/FormComponents/FormTextArea/FormTextArea';
import { ValidationService } from '../../../services/validation-service';

interface IFirmwareIdAndVersion {
  Id?: number,
  Version: string,
}
interface Props {
  dataItem: Intercom;
  sendClose: any;
  commandName?: IntercomDialogEnum;
}

const successMessage = 'The command has been executed successfully.';
const validationMessage = ValidationService.defaultFieldValidationMessage();

const IntercomActions = (props: Props) => {
  const dataItem = props.dataItem;
  const { openConfirmDialog } = useConfirmDialog();
  const [visible, setVisible] = useState<boolean>(false);
  const [firmwareVersions, setFirmwareVersions] = useState<IFirmwareIdAndVersion[]>([]);
  const [currentFirmwareVersion, setCurrentFirmwareVersion] = useState<IFirmwareIdAndVersion>();
  const [dialogConfig, setDialogConfig] = useState<IntercomDialogConfig>({ title: '', isUpgrade: false, mainButtonName: '', type: 0 });
  const [showIntercomDetailDialog, setShowIntercomDetailDialog] = useState<boolean>(false);

  useEffect(() => {
    switch (props.commandName) {
      case IntercomDialogEnum.VendGate:
        vendGate();
        return;
      case IntercomDialogEnum.OpenGate:
        openGate();
        return;
      case IntercomDialogEnum.CloseGate:
        closeGate();
        return;
      case IntercomDialogEnum.Upgrade:
        upgrade();
        return;
      case IntercomDialogEnum.Reboot:
        reboot();
        return;
      case IntercomDialogEnum.Detail:
        openDetails();
        return;
      case IntercomDialogEnum.Default:
        setVisible(false);
        return;
      default:
        return;

    }
  }, [props.commandName])

  if (!dataItem) {
    return null;
  }

  const handleSubmit = (formItem: any) => {
    switch (dialogConfig.type) {
      case IntercomDialogEnum.VendGate:
        apiClient().intercoms.vendGate(dataItem?.WanMac, formItem.Note).then((response: AxiosResponse<boolean>) => {
          notifications.success(successMessage);
          closeDialog();
        }, (er) => {
          console.log(er);
        });
        return;
      case IntercomDialogEnum.OpenGate:
        apiClient().intercoms.openGate(dataItem?.WanMac, formItem.Note).then((response: AxiosResponse<boolean>) => {
          notifications.success(successMessage);
          closeDialog();
        }, (er) => {
          console.log(er);
        });
        return;
      case IntercomDialogEnum.CloseGate:
        apiClient().intercoms.closeGate(dataItem?.WanMac, formItem.Note).then((response: AxiosResponse<boolean>) => {
          notifications.success(successMessage);
          closeDialog();
        }, (er) => {
          console.log(er);
        });
        return;
      default:
        notifications.error('Something went wrong.');
        closeDialog();
        return;
    }
  };

  const closeDialog = () => {
    setVisible(false);
    props.sendClose(true);
  };

  const vendGate = () => {
    setDialogConfig({ title: 'Vend Gate', isUpgrade: false, mainButtonName: 'Vend', type: IntercomDialogEnum.VendGate });
    setVisible(true);
  };

  const openGate = () => {
    setDialogConfig({ title: 'Open Gate', isUpgrade: false, mainButtonName: 'Open', type: IntercomDialogEnum.OpenGate });
    setVisible(true);
  };

  const closeGate = () => {
    setDialogConfig({ title: 'Close Gate', isUpgrade: false, mainButtonName: 'Close', type: IntercomDialogEnum.CloseGate });
    setVisible(true);
  };

  const upgrade = () => {
    apiClient().firmware.getAll().then((response: AxiosResponse<Firmware[]>) => {
      let mappedFirmwares: IFirmwareIdAndVersion[] = response.data.map((fw: Firmware) => {
        return { Id: fw.Id, Version: fw.Version };
      }).filter((mf) => mf.Version !== dataItem.Firmware);
      setCurrentFirmwareVersion(mappedFirmwares[0]);
      setFirmwareVersions(mappedFirmwares);
      setDialogConfig({ title: 'Upgrade Intercom', isUpgrade: true, mainButtonName: 'Upgrade', type: IntercomDialogEnum.Upgrade });
      setVisible(true);
    });
  };

  const upgradeIntercom = () => {
    let upgradeIntercomModel: IUpgradeActionIntercom = {
      WanMac: dataItem?.WanMac,
      Sip: dataItem?.SipAddress,
      Id: currentFirmwareVersion?.Id,
    };
    apiClient().intercoms.upgrade(dataItem?.WanMac, upgradeIntercomModel).then((response: AxiosResponse<boolean>) => {
      notifications.success(successMessage);
      closeDialog();
    }, (er) => {
      console.log(er);
      closeDialog();
    });
  };

  const onChangeUpgradeVersion = (event: DropDownListChangeEvent) => {
    if (event.value) {
      setCurrentFirmwareVersion(event.value);
    }
  };

  const openDetails = () => {
    setShowIntercomDetailDialog(true);
  };

  const reboot = () => {
    openConfirmDialog('Reboot Intercom',
      `Are you sure you want to reboot this intercom? This action cannot be undone.`, 'Reboot')
      .then((result) => {
        if (result) {
          apiClient().intercoms.reboot(dataItem.WanMac).then((res) => {
            notifications.success('Reboot process for the intercom has been initiated successfully.');
          }, (er) => {
            notifications.error('An error occurred while initiating the reboot process. Please try again.');
          });
        }
      });
  };

  return (
    <>
      {visible && (
        <Dialog width={422} title={dialogConfig.title} onClose={closeDialog}>
          {dialogConfig.isUpgrade ? (
            <>
              <div style={{ marginBottom: 20 }}>
                Please choose the firmware version to proceed with the upgrade
              </div>
              <FieldWrapper>
                <Label className={styles.fieldLabel}>Firmware</Label>
                <DropDownList
                  dataItemKey={'Id'}
                  textField={'Version'}
                  value={currentFirmwareVersion}
                  onChange={onChangeUpgradeVersion}
                  data={firmwareVersions}
                />
              </FieldWrapper>
              <div className={styles.modalActionBar}>
                <button className={styles.actionButtonGray} onClick={closeDialog}>Cancel</button>
                <button className={styles.actionMainButton}
                        onClick={upgradeIntercom}
                        type='button'>{dialogConfig.mainButtonName}</button>
              </div>
            </>
          ) : (
            <Form
              onSubmit={handleSubmit}
              render={(formRenderProps) => (
                <FormElement>
                  <Field
                    id={'noteId'}
                    name={'Note'}
                    label={'Note'}
                    component={FormTextArea}
                    validator={(value) => !!value ? '' : validationMessage}
                  />
                  <div className={styles.modalActionBar}>
                    <button className={styles.actionButtonGray} onClick={closeDialog}>Cancel</button>
                    <button className={styles.actionMainButton}
                            disabled={!formRenderProps.allowSubmit}
                            type='submit'>{dialogConfig.mainButtonName}</button>
                  </div>
                </FormElement>
              )}
            />
          )}
        </Dialog>
      )}
      {showIntercomDetailDialog &&
        <IntercomDetailDialog intercomWanMac={dataItem.WanMac} showDialogHandler={setShowIntercomDetailDialog}></IntercomDetailDialog>}
    </>
  )
}
export default IntercomActions;
