import { useState } from 'react';
import { getApiForLoader } from './api';

const API_REQUEST_EXCEPTIONS = ['/device/list'];

function requestExistException(url: string) {
  let isExistException = false;
  API_REQUEST_EXCEPTIONS.forEach((exception: string) => {
    if (url.includes(exception)) {
      isExistException = true;
    }
  });
  return isExistException;
}

const ApiLoaderShow = () => {
  const [loadingShow, setLoadingShow] = useState<boolean>(false);
  let totalApiRequests: Array<any> = [];
  const api = getApiForLoader();
  api.interceptors.request.use(
    async (config: any) => {
      if (!requestExistException(config.url)) {
        totalApiRequests.push(config);
      }

      if (totalApiRequests.length === 0) {
        setLoadingShow(false);
      } else {
        setLoadingShow(true);
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  api.interceptors.response.use(
    (next) => {
      totalApiRequests.shift();

      if (totalApiRequests.length === 0) {
        setLoadingShow(false);
      } else {
        setLoadingShow(true);
      }

      return Promise.resolve(next);
    },
    (error) => {
      totalApiRequests.shift();

      if (totalApiRequests.length === 0) {
        setLoadingShow(false);
      } else {
        setLoadingShow(true);
      }
      return Promise.reject(error);
    },
  );
  return loadingShow;
};

export default ApiLoaderShow;
